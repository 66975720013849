/**
 * analytics.tsx
 *
 * This module is used as an abstraction layer for the NextJS app router's
 * client server code-splitting.  Client side event listeners (like onClick)
 * cannot be exist inside server components.
 */
'use client'

import React, {
  type ComponentProps,
  type ElementType,
  type MouseEvent,
  useContext,
  useEffect,
  useMemo,
  type ReactElement,
} from 'react'
import {
  searchNamespaceLabelMap,
  type SearchNamespace,
} from '@app/[locale]/(main)/search/_components/search-session'
import useAnalytics, {
  type SPContext,
  type SPEvent,
  type TrackMerge,
  type TrackProps,
} from '@hooks/use-analytics'
import {
  BlockContext,
  ItemContext,
  PageContext,
  PlaceContext,
  ProviderContext,
} from '@lib/analytics'
import { trackAlgoliaConversionEvent } from '@lib/utilities/algolia-utilities'
import { Link } from '@shc/ui'
import { type SendEventForHits } from 'instantsearch.js/es/lib/utils'

export interface AnalyticsProps {
  view?: boolean
  click?: SPEvent
  expand?: SPEvent
  collapse?: SPEvent
  contexts?: SPContext[]
  children?: ReactElement
  merge?: TrackMerge | TrackProps
}

/**
 * An intermediary wrapper for containing client side side effects
 * for use with the NextJS App Router. This wrapper will reduce
 * the amount of code shipped to the browser.
 *
 * @example
 * // For page view tracking:
 * <Analytics
 *   click={{ name: 'view', data:{} }}
 *   contexts={[{
 *      name: 'page',
 *      data: {
 *        page_type: 'article',
 *        language: 'en-US',
 *        page_entry_id: 'abc123',
 *        tags: ['awesome', 'cool'],
 *     },
 *    }]}
 * />
 *
 * // For event tracking:
 * <Analytics
 *     click={{
 *       name: 'navigation_click',
 *       data: {
 *         navigation_tree: '???',
 *         navigation_subject: link1.name,
 *         navigation_level: 1,
 *         navigation_url: link1.route,
 *       },
 *     })}
 *     contexts={[{ name: 'section', data: { section_name: 'header' } }]}>
 * </Analytics>
 * @returns
 */

function Analytics({
  view,
  click,
  expand,
  collapse,
  contexts = [],
  children,
}: Readonly<AnalyticsProps>) {
  const { track, trackPageView } = useAnalytics()
  const pageContext = useContext(PageContext)
  const placeContext = useContext(PlaceContext)
  const providerContext = useContext(ProviderContext)
  const blockContext = useContext(BlockContext)
  const itemContext = useContext(ItemContext)

  const contextsAppended = useMemo(() => {
    let result = contexts.slice() // copy array
    if (pageContext) result.push(pageContext)
    if (placeContext) result.push(placeContext)
    if (providerContext) result.push(providerContext)
    if (blockContext) result.push(blockContext)
    if (itemContext) result.push(itemContext)
    return result
  }, [contexts, pageContext, providerContext, placeContext, blockContext, itemContext])

  const oneRequired: { [key: string]: boolean | SPEvent | undefined } = {
    view,
    click,
    expand,
    collapse,
  }
  const requiredPropsSent = Object.keys(oneRequired).filter(
    (prop) => oneRequired[prop] !== undefined
  )

  // GUARDS
  if (requiredPropsSent.length !== 1) {
    throw new Error(
      'One and only one of the following props can be present: "view", "click", "expand", "collapse"'
    )
  }

  if (view && children) {
    throw new Error('If "view" prop is present, there should be no children')
  }

  // TRACK PAGE VIEW
  useEffect(() => {
    if (view) {
      trackPageView({ contexts: contextsAppended })
    }
  }, [contextsAppended, contexts, trackPageView, view])

  if (!children) return null

  const handler = (event: SPEvent) => {
    track({ event, contexts: contextsAppended })
  }

  // TRACK NON-VIEW EVENTS
  return React.cloneElement(children, {
    ...(click && { onClick: () => handler(click) }),
    ...(expand && { onClick: () => handler(expand) }),
    ...(collapse && { onClick: () => handler(collapse) }),
  })
}

/**
 * Extracts the component text from a mouse event.
 *
 * This function determines the appropriate text to use for tracking purposes based on the anchor element of the mouse event.
 * It prioritizes the `aria-label` attribute if it exists, and falls back to the text content of the anchor element.
 *
 * The resulting text is truncated to 64 characters for Algolia and non-visible ASCII characters are replaced with spaces.
 *
 * @param {MouseEvent} mouseEvent - The mouse event.
 * @returns {string} The component text, Algolia eventName is limited to 64 characters.
 */
const extractComponentText = (mouseEvent: MouseEvent): string => {
  const anchorElement = mouseEvent.currentTarget as HTMLAnchorElement
  let componentText = anchorElement.getAttribute('aria-label') ?? anchorElement.textContent

  // Replace non-visible ASCII characters with spaces
  componentText = componentText?.replace(/[^\x20-\x7E]+/g, ' ') ?? ''

  return componentText.slice(0, 64)
}

/**
 * If text is a phone number replace with "Phone Number" for tracking purposes.
 *
 * @param {string} textContent - The text content to filter.
 * @returns {string} A message indicating whether a phone number was clicked or the original text content was clicked.
 */
const filterPhoneNumber = (textContent: string): string => {
  const phoneNumberRegex = /^(\+1\s?)?\b\d{3}[-.]?\d{3}[-.]?\d{4}\b$/
  return phoneNumberRegex.test(textContent) ? 'Phone number' : `${textContent}`
}

/**
 * Handles Algolia conversion event.
 * If no objectId is passed, default objectId to the current URL.
 *
 * @param {AlgoliaData} algolia - The Algolia data.
 * @param {MouseEvent} mouseEvent - The mouse event.
 *
 * @see {@link https://www.algolia.com/doc/api-reference/api-methods/converted-object-ids-after-search/}
 */
const handleAlgoliaConversion = (algolia: AlgoliaData, mouseEvent: MouseEvent) => {
  if (algolia?.conversion) {
    const componentText = algolia.conversion.eventName ?? extractComponentText(mouseEvent)
    const eventName = filterPhoneNumber(componentText)
    const defaultedObjectId = algolia.conversion.objectId ?? window.location.href

    trackAlgoliaConversionEvent(algolia.conversion.namespace, defaultedObjectId, eventName)
  }
}

/**
 * Handles Algolia search result click events.
 * @param {AlgoliaData} algolia - The Algolia data.
 *
 * @see {@link https://www.algolia.com/doc/guides/sending-events/instantsearch/send-events/#using-instantsearch-widgets}
 */
const handleAlgoliaClick = (algolia: AlgoliaData) => {
  if (algolia?.click) {
    algolia.click.sendEvent(
      'click',
      algolia.click.hit,
      `${searchNamespaceLabelMap[algolia.click.namespace]} result`
    )
  }
}

/**
 * Derive Snowplow component context from mouse event.
 *
 * @param {MouseEvent} mouseEvent - The mouse event.
 * @returns {SPContext[]} The component contexts.
 */
const deriveComponentContext = (mouseEvent: MouseEvent): SPContext[] => {
  const anchorElement = mouseEvent.currentTarget as HTMLAnchorElement
  const componentUrl = anchorElement.href
  const componentText = extractComponentText(mouseEvent)

  return [
    {
      name: 'component',
      data: {
        component_text: componentText,
        component_url: componentUrl,
      },
    },
  ]
}

export type SnowplowData = {
  event?: SPEvent
  contexts?: SPContext[]
}

/* Represents the data for Algolia events. */
export type AlgoliaData = {
  /*  Data for Algolia click events. */
  click?: {
    /* The namespace for the Algolia event. */
    namespace: SearchNamespace
    /* Function to send the Algolia event. */
    sendEvent: SendEventForHits
    /* The hit object associated with the Algolia event. */
    hit: any
    /* Optional name for the Algolia event - default derives from text of child component. */
    eventName?: string
  }

  /* Data for Algolia conversion events. */
  conversion?: {
    /* The namespace for the Algolia event. */
    namespace: SearchNamespace
    /* Optional object ID for the Algolia conversion event. */
    objectId?: string
    /* Optional name for the Algolia event - default derives from text of child component. */
    eventName?: string
  }
}

/**
 * Props for the AnalyticsLink component.
 *
 * @template E - The type of the element or component to render.
 * @typedef {Object} AnalyticsLinkProps
 * @property {E} [as] - The component type to render as the root element.
 * @property {ElementType} [asPassthru] - The component type to pass through to the root element.
 * @property {SnowplowData} [snowplow] - Optional Snowplow data for tracking events.
 * @property {AlgoliaData} [algolia] - Optional Algolia data for tracking events.
 */
type AnalyticsLinkProps<E extends ElementType> = Omit<ComponentProps<E>, 'as'> & {
  as?: E
  asPassthru?: ElementType
  snowplow?: SnowplowData
  algolia?: AlgoliaData
}

/**
 * AnalyticsLink component that tracks Snowplow and Algolia events.
 *
 * @template E - The type of the element or component to render.
 * @param {AnalyticsLinkProps<E>} props - The component props.
 * @param {E} [props.as] - The component type to render as the root element.
 * @param {ElementType} [props.asPassthru] - The component type to pass through to the root element.
 * @param {SnowplowData} [props.snowplow] - Optional Snowplow data for tracking events.
 * @param {AlgoliaData} [props.algolia] - Optional Algolia data for tracking events.
 * @returns {JSX.Element} The rendered component.
 *
 * @example
 * // Usage example
 * <AnalyticsLink
 *   as={Button}
 *   asPassthru={NextLink}
 *   href={link.route}
 *   snowplow={{ contexts: snowplowContexts }}
 *   algolia={{ conversion: { namespace, objectId } }}>
 *   Click Me
 * </AnalyticsLink>
 *
 * @note
 * - Snowplow event is ALWAYS triggered - defaults to component_click
 * - Derives Snowplow component data from the mouse event
 * - Algolia events (click or conversion) are optionally triggered based on the props
 *
 * @assumption
 * - It is assumed that the necessary contexts and hooks are properly set up and provided in the application.
 */
const AnalyticsLink = <E extends ElementType>({
  as,
  asPassthru,
  snowplow,
  algolia,
  ...props
}: AnalyticsLinkProps<E>): JSX.Element => {
  // default to Link component if no 'as' prop is provided
  const Component = as ?? Link
  const { track } = useAnalytics() // snowplow tracking hook

  // Set Snowplow defaults
  const defaultSnowplowEvent: SPEvent = {
    name: 'component_click',
    data: {},
  }
  if (!snowplow) snowplow = { event: defaultSnowplowEvent, contexts: [] }
  if (!snowplow.event) snowplow.event = defaultSnowplowEvent
  if (!snowplow.contexts) snowplow.contexts = []

  // Environment contexts -- Filter out any null or undefined contexts
  const environmentContexts = [
    useContext(PageContext),
    useContext(PlaceContext),
    useContext(ProviderContext),
    useContext(BlockContext),
    useContext(ItemContext),
  ].filter((context): context is NonNullable<typeof context> => Boolean(context))

  // Combine environment contexts with passed-in contexts
  const combinedContexts = useMemo(
    () => [...(snowplow?.contexts ?? []), ...environmentContexts],
    [snowplow?.contexts, environmentContexts]
  )

  const handleClick = (mouseEvent: MouseEvent) => {
    // Check if the component context already exists in combinedContexts
    const hasComponentContext = combinedContexts.some((context) => context.name === 'component')

    // If the component context does not exist, derive it from the mouseEvent
    const componentContext = hasComponentContext ? [] : deriveComponentContext(mouseEvent)

    // Combine all contexts
    const finalContexts = [...componentContext, ...combinedContexts]

    // ALWAYS track a snowplow event -- defaults to component_click
    track({
      event: snowplow.event ?? defaultSnowplowEvent,
      contexts: finalContexts,
    })

    // Handle Algolia events if provided
    if (algolia) {
      handleAlgoliaClick(algolia)
      handleAlgoliaConversion(algolia, mouseEvent)
    }

    // Call passed through onClick event if present
    props.onClick?.(mouseEvent)
  }

  return <Component as={asPassthru} {...props} onClick={handleClick} />
}

AnalyticsLink.displayName = 'AnalyticsLink'

export default Analytics
export { AnalyticsLink }
